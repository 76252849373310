import { AdCardProduct } from '../types';
import { Product } from '@/components/adView/types';

const getAdCardProduct = (product: Product): AdCardProduct => (
  {
    list_id: product.list_id,
    title: product.subject,
    price: product.price.value,
    parameters: product.parameters,
    friendly_url: product.url,
    image_url: (product.images?.length
      && product.images[0].image_size_variations?.find(
        (image) => image.url.includes('bigthumbs'),
      )?.url)
      || '',
    // @ts-expect-error .at is valid
    categoryName: (product.category?.name || product.category_tree?.at(-1)?.name || ''),
    jobType: product.parameters.find((param) => param.key === 'jobtype')?.values[0].label || '',
    location: product.parameters.find((param) => param.key === 'city')?.values[0].label || '',
    salary_interval: product.parameters.find((param) => param.key === 'price_dimension')?.values[0]?.label,
  }
);

export default getAdCardProduct;
