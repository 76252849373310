var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"qegSearzRdGaZOQltuwvi"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable max-len */
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { sentry } from '@/config';

const ignorableErrors = [
  /hydration/gi,
  /hydrating/gi,
  /Minified React error #418/gi,
  /Minified React error #421/gi, // This Suspense boundary received an update before it finished hydrating.
  /Minified React error #422/gi, // There was an error while hydrating this Suspense boundary. Switched to client rendering.
  /Minified React error #423/gi, // There was an error while hydrating but React was able to recover by instead client rendering the entire root.
  /Minified React error #424/gi, // This root received an early update, before anything was able hydrate.
  /Minified React error #425/gi, // Text content does not match server-rendered HTML
  /tracker.evaluateEventInputs/gi,
  /adsbygoogle/gi,
  /rubiconproject/gi,
];

Sentry.init({
  dsn: sentry.dsn,
  ignoreErrors: ignorableErrors,
  integrations: [Sentry.dedupeIntegration()],
  beforeSend(event, hint) {
    const error = hint.originalException as any;

    if (
      error
      && error.message
      && ignorableErrors.some((regex) => regex.test(error.message))
    ) {
      return null;
    }
    return event;
  },
  debug: false,
});
